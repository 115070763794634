import { ToastContainer, Slide } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CssBaseline from "@mui/material/CssBaseline";

import { ThemeProvider } from "@mui/material/styles";
import theme from "styles/theme";
import MuiLocalization from "./Provider/MuiLocalization";

export default function InitHOC({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MuiLocalization>
        <ToastContainer
          transition={Slide}
          position="bottom-center"
          limit={3}
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick
          draggable
          pauseOnFocusLoss={false}
          pauseOnHover
        />
        {children}
      </MuiLocalization>
    </ThemeProvider>
  );
}
