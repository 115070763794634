import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {}
  // allow configuration using `createTheme`
  interface ThemeOptions {}

  interface BreakpointOverrides {
    xs: true; // removes the `xs` breakpoint
    sm: true;
    md: true;
    lg: true;
    xl: true;
    mobile: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }

  export default function createTheme(options?: ThemeOptions, ...args: object[]): Theme;
}

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    [key: string]: {
      light: string;
      main: string;
      dark: string;
      contrastText: string;
    };
  }

  interface PaletteOptions {
    [key: string]: {
      light: string;
      main: string;
      dark: string;
      contrastText: string;
    };
  }

  export default function createPalette(palette: PaletteOptions): Palette;
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    flat: true;
    classic: true;
    danger: true;
    blend: true;
  }
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides {
    danger: true;
  }
}

const theme = createTheme({
  palette: {
    blend: {
      light: "#ffffff",
      main: "#E0E0E0",
      dark: "#aeaeae",
      contrastText: "#000000dd",
    },
    flat: {
      light: "#444444",
      main: "#202020",
      dark: "#000000",
      contrastText: "#FFFFFF",
    },
    primary: {
      light: "#9FC1D6",
      main: "#7AA9C6",
      dark: "#5793B7",
      contrastText: "#FAFAFA",
    },
    secondary: {
      light: "#B3E1EA",
      main: "#89D0DF",
      dark: "#63C1D5",
      contrastText: "#3D404E",
    },
    info: {
      light: "#90D0D0",
      main: "#6CC0C0",
      dark: "#4BAFAF",
      contrastText: "#FAFAFA",
    },
    warning: {
      light: "#FEF3DC",
      main: "#FDE2A9",
      dark: "#FCD278",
      contrastText: "#D69B3D",
    },
    error: {
      light: "#FADBE0",
      main: "#F4B0BA",
      dark: "#EE8292",
      contrastText: "#9A4141",
    },
    danger: {
      light: "#ffb9b8",
      main: "#ED8888",
      dark: "#b8595b",
      contrastText: "#000000",
    },
    success: {
      light: "#A6D8C2",
      main: "#84C9AB",
      dark: "#60B992",
      contrastText: "#296513",
    },
    classic: {
      light: "#ffe174",
      main: "#F2AF44",
      dark: "#bb800b",
      contrastText: "#000000",
    },
    background: {
      //@ts-ignore
      default: "#E5E5E5",
      paper: "#FFFFFF",
    },
    text: {
      primary: "#3D3D3D",
      secondary: "#3D3D3DBF",
      disabled: "#3D3D3D30",
    },
  },
  sizes: {
    A4: {
      original: {
        height: "3508px",
        width: "2480px",
      },
      sm: {
        height: `${3508 / 12}px`,
        width: `${2480 / 12}px`,
      },
      md: {
        height: `${3508 / 8}px`,
        width: `${2480 / 8}px`,
      },
      lg: {
        height: `${3508 / 4}px`,
        width: `${2480 / 4}px`,
      },
      xl: {
        height: `${3508 / 2.5}px`,
        width: `${2480 / 2.5}px`,
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      mobile: 375,
      tablet: 768,
      laptop: 1280,
      desktop: 1920,
    },
  },
});

export default theme;
